@import url("http://fonts.cdnfonts.com/css/league-spartan");
html,
body,
p {
  font: 100 1.215rem/1.305rem "League Spartan", sans-serif;
}
 {
  margin: 0;
  font-family: "League Spartan", sans-serif;
}
@font-face {
  font-family: "League Spartan";
  font-style: normal;
  font-weight: 700;
  src: local("League Spartan"),
    url("https://fonts.cdnfonts.com/s/16416/LeagueSpartan-Bold.woff")
      format("woff");
}
